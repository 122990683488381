<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { debounce } from 'lodash-es'
import Empty from '../empty/Empty.vue'
import { coinsEvent } from '@/utils/track/coins'

const { t } = useLang()
const { locale } = useI18n()
const anchor = ref()
// const route = useRoute()
// const router = useRouter()
export interface List {
  month: string
  income: string
  outcome: number
  timeout: string
  detailRespList: DetailRespList[]
}

export interface DetailRespList {
  cost: number
  type: number
  name: string
  time: string
  expire: string
  strategyId: string
  orderId: string
  detailLink: string
}

// nav导航选中索引
const activeIndex = ref(0)
// 滑动动画样式
const lineBar = ref({
  width: '120px',
  left: '0',
})

// 菜单
const menuList = [
  {
    title: t('coins.p_coins_menu_all'),
    index: 0,
  },
  {
    title: t('coins.p_coins_menu_income'),
    index: 1,
  },
  {
    title: t('coins.p_coins_menu_outcome'),
    index: 2,
  },
  {
    title: t('coins.p_coins_menu_expired'),
    index: 3,
  },
]

// 积分列表信息
const coinsList = ref<List[]>([])
// 是否还有更多数据
const unfinished = ref(true)
// 是否正在请求
const inFetching = ref(false)
// 是否是首次初始化
const showInit = ref(true)
// 前端记录的页数
const pageNum = ref(0)
// 缓存是否有数据
const hasDataCache = ref(false)

// 切换导航，处理下划线样式
function changeNav(e: any, index: number) {
  if (activeIndex.value === index) {
    return
  }
  window.scrollTo(0, 0)
  resetData()
  activeIndex.value = index
  const left = e.target.offsetLeft
  const width = e.target.offsetWidth
  lineBar.value = {
    width: `${width || 120}px`,
    left: `${left || 0}px`,
  }
  // 获取积分列表
  getCoinsList()

  try {
    coinsEvent.clickEvent(menuList[index].title)
  }
  catch (e) {
    console.log(e)
  }
}

// 初始化第一个导航样式
function initFirstNav() {
  const firstNav = document.querySelector('.menu-content li')
  // const left = firstNav?.getBoundingClientRect().left
  const width = firstNav?.getBoundingClientRect().width
  lineBar.value = {
    width: `${width}px`,
    left: '0px',
  }
}

// 判断底部锚标元素是否进入到可视区域
function handleScroll() {
  // console.log('scrolling')
  const anchorElement = anchor.value
  if (!anchorElement) {
    return
  }
  const { top } = anchorElement.getBoundingClientRect()
  const windowHeight = window.innerHeight - 120
  // console.log('top', top, 'windowHeight', windowHeight)
  if (top < windowHeight) {
    getCoinsList()
  }
}
// 防抖处理
const debounceHandleScroll = debounce(handleScroll, 300)

let lastType = 0
// 正在发送的请求
let cancel: any
let addScolled = false
// 获取积分列表
async function getCoinsList() {
  // 如果正在请求中，不再请求
  if (inFetching.value && lastType === activeIndex.value) {
    return
  }
  lastType = activeIndex.value
  inFetching.value = true
  if (cancel) {
    cancel()
    cancel = null
  }
  const cancelPromise: Promise<string | undefined> = new Promise((resolve, reject) => {
    cancel = reject
  })
  // 取消判断,只针对支持的浏览器生效
  // if ('AbortController' in window) {
  //   abortController = new AbortController()
  // }

  // const listResponse = await http({
  //   url: '/sea_whale_api/integral/list',
  //   baseURL: '/sea_shell',
  //   method: 'POST',
  //   abortController,
  //   body: {
  //     type: activeIndex.value,
  //     pageNum: pageNum.value,
  //   },
  // })
  try {
    const listResponse = await request({
      method: 'POST',
      url: '/sea_shell/sea_whale_api/integral/list',
      data: {
        type: activeIndex.value,
        pageNum: pageNum.value,
      },
      cancel: cancelPromise,
      includeClientInfo: false,
      responseOnlyBody: false,
    })
    // 状态处理
    if (listResponse?.body.list?.length) {
      coinsList.value = [...coinsList.value, ...listResponse.body?.list]
      hasDataCache.value = true
      // pageNum.value++
      pageNum.value = listResponse?.body.pageNum + 1
      // 增加滚动获取
      if (!addScolled) {
        window.addEventListener('scroll', debounceHandleScroll, false)
        addScolled = true
      }
      // window.addEventListener('scroll', debounceHandleScroll, false)
    }
    else {
      window.removeEventListener('scroll', debounceHandleScroll, false)
      unfinished.value = false
      addScolled = false
    }
  }
  catch (e) {
    console.log(e)
    window.removeEventListener('scroll', debounceHandleScroll, false)
    unfinished.value = false
    addScolled = false
  }
  // else {
  //   // 移除滚动监听
  //   window.removeEventListener('scroll', debounceHandleScroll, false)
  //   unfinished.value = false
  // }

  // 处理menu样式
  if (hasDataCache.value && showInit.value && !activeIndex.value) {
    nextTick(() => {
      initFirstNav()
    })
  }
  showInit.value && (showInit.value = false)
  inFetching.value = false
  // abortController = null
  cancel = null
}

// 切换tab，重置数据
function resetData() {
  coinsList.value = []
  unfinished.value = true
  showInit.value = true
  pageNum.value = 0
}

onMounted(() => {
// console.log('mounted')
// initFirstNav()
  getCoinsList()
})
</script>

<template>
  <div
    v-if="!unfinished && !coinsList.length && !hasDataCache"
    class="flex items-center justify-center h-[320px]"
  >
    <Empty :has-btn="true" />
  </div>
  <div v-else class="content flex flex-col">
    <div v-show="hasDataCache" class="menu">
      <ul class="flex menu-content">
        <li
          v-for="item in menuList"
          :key="item.index"
          class="text-[20px] text-[#000]"
          :class="{ 'font-semibold active': activeIndex === item.index }"
          @click="changeNav($event, item.index)"
        >
          {{ item.title }}
        </li>
        <div class="line" :style="lineBar" />
      </ul>
    </div>
    <div
      v-if="hasDataCache && !unfinished && !coinsList.length"
      class="flex items-center justify-center flex-1"
    >
      <Empty />
    </div>
    <div v-else class="list flex-1 flex flex-col">
      <div
        v-if="inFetching && showInit"
        class="big-loading flex flex-1 justify-center items-center"
      >
        <img
          class="w-[92px] h-[92px] block mx-auto"
          src="~/assets/img/coins/loading-hg.gif"
          alt=""
        >
      </div>
      <!-- 数据列表 -->
      <section v-else>
        <!-- <div class="year font-bold mb-[8px] text-[20px]">2024年</div> -->
        <div
          v-for="(item, index) in coinsList"
          :key="index"
          class="pb-[24px] pt-[18.5px] bg-white mb-[16px] rounded-[8px]"
        >
          <section class="list-title" :data-tip="item.month">
            <div class="mt-[8px] text-[12px] pl-[24px]">
              <template v-if="item.income && [0, 1].includes(activeIndex)">
                <span class="text-black">{{
                  t('coins.p_coins_list_income')
                }}</span><span class="text-[#FF6B00] mr-[12px] ml-[2px] font-bold">
                  {{ item.income }}</span>
              </template>
              <template v-if="item.outcome && [0, 2].includes(activeIndex)">
                <span class="text-black">{{ t('coins.p_coins_list_outcome') }} </span>
                <span class="text-black mr-[12px] ml-[2px] font-bold">
                  {{ Math.abs(item.outcome) }}</span>
              </template>
              <template v-if="item.timeout && [0, 3].includes(activeIndex)">
                <span class="text-black">{{ t('coins.p_coins_list_expend') }}</span>
                <span class="text-black ml-[2px] font-bold">
                  {{ item.timeout }}
                </span>
              </template>
            </div>
          </section>
          <section
            v-for="(son, inx) in item.detailRespList"
            :key="`son${inx}`"
            class="list-content"
          >
            <div class="flex items-center">
              <div class="flex-1">
                <div class="flex text-[16px] mb-[4px] text-black">
                  <span class="text-[14px]">{{ son.name }}</span>
                  <span class="font-bold ml-[auto]" :class="{ org: son.cost > 0 }">
                    {{ son.cost > 0 ? '+' : '' }}{{ son.cost }}
                  </span>
                </div>
                <p class="text-[12px] text-[#999]">
                  {{ son.time }}
                </p>
                <p v-if="son.orderId" class="text-[#999] t-[6px] flex items-center">
                  <template v-if="son.detailLink">
                    <a class="flex mt-[4px]" :href="son.detailLink" target="_blank">
                      <span class="text-[12px]">{{ t('coins.p_conis_number') + son.orderId }}</span>
                      <img class="w-[16px] h-[16px]" src="//file.hopegoocdn.com/hopegoo/web-pc/images/coins/coins_right.png" alt="coins_right">
                    </a>
                  </template>
                  <template v-else>
                    <span class="text-[12px]">{{ t('coins.p_conis_number') + son.orderId }}</span>
                  </template>
                  <!-- <span class="text-[12px]">{{ t('coins.p_conis_number') + son.orderId }}</span>
                  <a v-if="son.detailLink" :href="son.detailLink" target="_blank">
                    <img class="w-[16px] h-[16px]" src="//file.hopegoocdn.com/hopegoo/web-pc/images/coins/coins_right.png" alt="coins_right">
                  </a> -->
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>
      <!-- loading样式模块 -->
      <div class="last-content">
        <div
          v-if="unfinished && inFetching && !showInit"
          class="loading flex items-center"
        >
          <img
            class="w-[16px] h-[16px] block mr-[6px]"
            src="~/assets/img/coins/loading.gif"
            alt=""
          >
          <p class="text-[#999] text-[16px]">
            {{ t('coins.p_conis_loading') }}
          </p>
        </div>
        <div v-if="!inFetching && !unfinished" class="tips text-[16px] text-[#999]">
          {{ t('coins.p_coins_list_tip') }}
        </div>
      </div>
      <div ref="anchor" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
body {
  overscroll-behavior: none;
}

.content {
  width: 100%;
  // min-height: 480px;
}

.org {
  color: #ff6b00;
}

.menu {
  width: 100%;
  background-color: #fff;
  height: 64px;
  line-height: 64px;

  &-content {
    width: 870px;
    margin: 0 auto;
    position: relative;

    li {
      margin-right: 80px;
      cursor: pointer;

      &.active {
        color: #00C868;
      }
    }

    .line {
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.3s;
      height: 2px;
      background-color: #00C868;
    }
  }
}

.list {
  width: 870px;
  margin: 0 auto;
  padding: 16px 0 80px;
  position: relative;

  &-title {
    line-height: 1;
    padding-bottom: 18.5px;
    border-bottom: 0.5px solid #e4e4e4;

    &::before {
      content: attr(data-tip);
      display: inline-block;
      height: 24px;
      font-size: 16px;
      padding-left: 24px;
      font-weight: bold;
      line-height: 24px;
      border-radius: 4px;
      color: #000;
      margin-right: 12px;
    }
  }

  &-content {
    padding-top: 24px;
    padding-bottom: 24px;
    // padding: 24px 0;
    margin: 0 24px;
    border-bottom: 1px solid #e4e4e4;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.last-content {
  line-height: 24px;
  height: 24px;
  margin-bottom: -24px;
}

// .tips {
//   line-height: 20px;
// }

// .loading {
//   line-height: 20px;
//   // position: absolute;
// }

.big-loading {
  // margin-top: 100px;
  height: 100%;
}
</style>
