import { trackEvent } from './index'

const isM = process.client && !navigator.userAgent.includes('AlipayHK')

export const coinsEvent = {
  /**
     *
     * @param content 点击内容
     * @param ptype 一级分类
     * @param stype 二级分类
     */
  clickEvent: (name: string) => {
    trackEvent({
      eventid: 11223,
      label: 'Tabclick',
      category: 'G_Coins_PC',
      action: 'click',
      value: {
        Tabclickname: name,
      },
    })
  },
  pageShowEvent: () => {
    trackEvent({
      eventid: 11222,
      label: 'PageLoad',
      category: 'G_Coins_PC',
      action: 'show',
      value: {},
    })
  },
}
